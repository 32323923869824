<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <icon-class-bar
          title="Zwischenbericht"
          :marks="true"
          :comments="true"
          :presence="true"
          current="quarter-marks"
          @edit-mode="editMode=true"
        />
        <v-card-text>
          <div
            class="d-flex justify-space-between"
          >
            <v-select
              v-model="o_CurrentStudies.i_StudiesID"
              :items="a_Studies"
              item-text="s_Name"
              item-value="i_StudiesID"
              filled
              label="Lehrgang"
              dense
              outlined
              color="success"
              @change="clearDataTables"
              class="mr-2"
            />
            <v-select
              v-model="o_CurrentClass.i_ClassID"
              :items="a_Class"
              item-text="s_Name"
              item-value="i_ClassID"
              filled
              label="Klasse"
              dense
              outlined
              color="success"
              class="mr-2"
              @change="getTermMarkDates()"
            />
            <v-select
              v-model="i_CurrentClassSubjectID"
              :items="a_ClassSubject"
              item-text="s_Name"
              item-value="i_ClassSubjectID"
              filled
              label="Fach"
              dense
              outlined
              color="success"
            />
          </div>
          <div class="d-flex flex-row-reverse mb-6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </div>
          <v-data-table
            v-model="selected"
            :search="search"
            :headers="a_Header"
            :items="a_Student"
            class="elevation-1 table-one"
            multi-sort
            dense
          >
            <template v-slot:item.s_Name="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  class="mr-2"
                  size="26"
                  dark
                >
                  <img
                    :src="require('@/assets/images/avatars/001-man.svg')"
                    alt=""
                  >
                </v-avatar>
                <p class="ma-0 font-weight-medium">
                  {{ item.s_Name }}
                </p>
              </div>
            </template>
            <template v-slot:footer>
              <div
                style="position: absolute"
                class="pl-2 pt-2"
              >
                <v-chip
                  class="ma-2"
                  label
                  color="teal"
                  text-color="white"
                >
                  Durchschnitt: {{ getAverage }}
                </v-chip>
              </div>
            </template>
          </v-data-table>
          <div
            v-if="editMode"
            class="d-flex flex-wrap mt-4"
          >
            <v-btn
              class="ma-2"
              outlined
              color="green"
              small
              @click="setTermMarks()"
            >
              Speichern
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="red"
              small
              @click="cancelSaveTermMarks()"
            >
              Abbrechen
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card class="">
        <v-card-title>
          <span class="text-h5">{{$t('grades.add_term_mark')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="o_NewTermMarkDate.s_Name"
                  :label="'* '+$t('general.name')"
                />
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="o_NewTermMarkDate.s_Factor"
                  :label="'* '+$t('grades.factor')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="dialog = false"
          >
            {{$t('general.cancel')}}
          </v-btn>
          <v-btn
            color="success darken-1"
            text
            @click="setTermMarkDate"
          >
            {{$t('general.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue'
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { decimal, between } from 'vuelidate/lib/validators'

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'ExperienceMarksPMA',
    },
    components: {
      IconClassBar,
    },
    mixins: [validationMixin],
    data () {
      return {
        o_NewTermMarkDate: {
          i_ExamTypeID: 1, // Erfahrungsnote
          s_Name: null,
          s_Factor: null,
        },
        o_CurrentClass: {
          i_ClassID: null,
          s_Name: null
        },
        o_CurrentStudies: {
          i_StudiesID: null,
          s_Name: null
        },
        i_CurrentClassSubjectID:  null,
        dialog: false,
        editMode: false,
        search: '',
        selected: [],
        a_Header: [],
        a_ConfigHeader: [
          {
            text: 'Lernende',
            align: 'start',
            sortable: false,
            value: 's_Name',
          },
          { text: 'Quartal', value: 'quarter' },
          { text: 'Prognose', value: 'snip' },
        ],
        a_ClassStudent: [],
        a_Studies: [],
        a_TermMarkDate: [],
        a_TermMarkError: [],
        a_QuarterMark: [],
        f_CurrentResult: null,
      }
    },
    watch: {
      i_CurrentClassSubjectID(val) {
        const La_SubjectTermMarkDate = this.a_TermMarkDate.filter(o_TermMarkDate => {
          return o_TermMarkDate.i_ClassSubjectID === val
        });
        this.a_Header = [...this.a_ConfigHeader];
        this.a_Header.splice(1, 0, ...La_SubjectTermMarkDate);
        this.getQuarterMarks(val);
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID']),
      nameHeaders() {
        return this.a_TermMarkDate.map(termMarkDate => {
          return termMarkDate.value
        });
      },
      a_Class() {
        const a_CurrentStudies = this.a_Studies.filter(o_Studies => {
          return o_Studies.i_StudiesID === this.o_CurrentStudies.i_StudiesID
        });
        if(a_CurrentStudies.length > 0)
          return a_CurrentStudies[0].a_Class;
        else return [];
      },
      a_ClassSubject() {
        const a_CurrentClass = this.a_Class.filter(o_Class => {
          return o_Class.i_ClassID === this.o_CurrentClass.i_ClassID
        });
        if(a_CurrentClass.length > 0)
          return a_CurrentClass[0].a_ClassSubject;
        else return [];
      },
      a_Student() {
        if(
          this.a_ClassStudent.length < 1 ||
          this.i_CurrentClassSubjectID === null
        ) return this.a_ClassStudent;
        return this.a_ClassStudent.map(o_Student => {
          return {
            ...o_Student, a_TermMarks: o_Student.a_TermMarks.filter(
            o_TermMark => {
              return o_TermMark.i_ClassSubjectID === this.i_CurrentClassSubjectID
            }
          )}
        });
      },
      getAverage() {
        let f_Average = 0;
        let f_SpinSum = 0;
        let i_CountStudents = 0;
        this.a_Student.forEach(o_Student => {
          if(o_Student.snip) {
            f_SpinSum += parseFloat(o_Student.snip);
            i_CountStudents++;
          }
        });
        if(i_CountStudents > 0) f_Average = f_SpinSum / i_CountStudents;
        return (f_Average).toFixed(2);
      }
    },
    methods: {
      addHeaderTermMark(Ao_TermMark) {
        this.a_Header.splice(this.a_Header.length - 1, 0, {
          s_Name: Ao_TermMark.s_Name,
          value: Ao_TermMark.i_TermMarkDateID,
          f_Factor: Ao_TermMark.s_Factor + '%',
          t_Date: '20.08.2021',
          i_TermMarkDateID: Ao_TermMark.i_TermMarkDateID
        })
        this.nameHeaders.push(Ao_TermMark.i_TermMarkDateID)
      },
      setTermMarkDate() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        this.dialog = false;
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark-date`, {
          i_ClassID: this.o_CurrentClass.i_ClassID,
          i_AddressRoleID: teacherID,
          i_PlanningPeriodID: this.getPlanningPeriodID,
          i_ExamTypeID: parseInt(this.o_NewTermMarkDate.i_ExamTypeID),
          s_Name: this.o_NewTermMarkDate.s_Name,
          f_Factor: parseFloat(this.o_NewTermMarkDate.s_Factor)
        }).then(
          response => {
            this.addHeaderTermMark({
              s_Name: this.o_NewTermMarkDate.s_Name,
              s_Factor: this.o_NewTermMarkDate.s_Factor
            });
          }
        ).catch(
          error => {
          }
        );
      },
      getTermMarkDates() {
        if(this.o_CurrentClass.i_ClassID === null) {
          this.a_TermMarkDate = [];
          return;
        }
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark-date`, {
          i_ClassID: this.o_CurrentClass.i_ClassID,
          i_AddressRoleID: teacherID,
          i_PlanningPeriodID: this.getPlanningPeriodID
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_TermMarkDate = response;
            this.a_Header.splice(1, 0, ...this.a_TermMarkDate);
            this.getTermMarks();
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getTermMarks() {
        if(this.o_CurrentClass.i_ClassID === null) {
          this.a_ClassStudent = [];
          return;
        }
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark`, {
          i_ClassID: this.o_CurrentClass.i_ClassID,
          i_AddressRoleID: teacherID,
          i_PlanningPeriodID: this.getPlanningPeriodID
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_ClassStudent = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getTeacherStudies() {
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/studies`, {
          i_AddressRoleID: teacherID,
          i_PlanningPeriodID: this.getPlanningPeriodID
        })
          .then(response => { 
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_Studies = response;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      setTermMarks() {
        if (this.$v.$invalid) return
        let teacherID = this.$smt.getAddressRoleID(this.$route.meta.role);
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/term-mark`, {
          a_Student: this.a_Student,
          i_AddressRoleID: teacherID,
          i_PlanningPeriodID: this.getPlanningPeriodID,
          i_ClassID: this.o_CurrentClass.i_ClassID,
        })
          .then(response => {
            this.editMode = false;
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.editMode=false; 
            this.getTermMarks();
            this.requestSent = true
            this.responseMessage = 'Resultate gesendet'
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      updateTermMark(Ao_Student, Ai_TermMarkDateID, event) {
        let Lo_NewTermMark;
        let Li_TermMarkIndex;
        this.validateResult(Ao_Student, Ai_TermMarkDateID, event);
        const La_CurrentTermMarkDate = this.a_TermMarkDate.filter(termMarkDate => {
          return termMarkDate.i_TermMarkDateID === Ai_TermMarkDateID
        });
        Li_TermMarkIndex = Ao_Student.a_TermMarks.findIndex(o_TermMark => {
          return o_TermMark.i_TermMarkDateID === Ai_TermMarkDateID
        });
        
        if(Li_TermMarkIndex === -1) {
          Lo_NewTermMark = {
            f_Factor: La_CurrentTermMarkDate[0].f_Factor,
            f_Value: parseFloat(event),
            i_TermMarkDateID: Ai_TermMarkDateID,
            i_TermMarkID: null,
            s_Comment: null,
          };
          Ao_Student.a_TermMarks.push(Lo_NewTermMark);
        } else {
          Ao_Student.a_TermMarks[Li_TermMarkIndex].f_Value = (event === '') ? null : parseFloat(event);
        }
      },
      getTermMarkForSlot(Ai_TermMarkDateID, Ao_Student) {
        const a_CurrentTermMark = Ao_Student.a_TermMarks.filter(o_TermMark => {
          return o_TermMark.i_TermMarkDateID === Ai_TermMarkDateID
        });
        if(0 in a_CurrentTermMark) return a_CurrentTermMark[0].f_Value;
        else return null;
      },
      clearDataTables() {
        this.a_ClassStudent = []; 
        this.a_TermMarkDate = [];
        this.a_QuarterMark = [];
        this.a_Header = [...this.a_ConfigHeader];
      },
      validateResult(Ao_Student, Ai_TermMarkDateID, Af_TermMark) {
        const Li_IndexError = this.a_TermMarkError.findIndex(error => {
          return error.i_AddressRoleID ===  Ao_Student.i_AddressRoleID &&
            error.i_TermMarkDateID === Ai_TermMarkDateID
        });
        this.f_CurrentResult = Af_TermMark;
        if(this.f_CurrentResult == null || this.f_CurrentResult == '') return;
        this.$v.f_CurrentResult.$touch();
        if(!this.$v.$invalid && Li_IndexError === -1) return;
        if(!this.$v.$invalid && Li_IndexError > -1) this.a_TermMarkError.splice(Li_IndexError, 1);
        if(this.$v.$invalid && Li_IndexError === -1) this.a_TermMarkError.push({
            i_AddressRoleID: Ao_Student.i_AddressRoleID,
            i_TermMarkDateID: Ai_TermMarkDateID,
            f_TermMark: Af_TermMark
          });
      },
      cancelSaveTermMarks() {
        this.editMode=false; 
        this.f_CurrentResult=null; 
        this.a_TermMarkError=[]; 
        this.$v.$reset()
      },
      getErrorMessages(Ai_AddressRoleID, Ai_TermMarkDateID) {
        const errors = []
        const Li_IndexError = this.a_TermMarkError.findIndex(error => {
          return error.i_AddressRoleID ===  Ai_AddressRoleID &&
            error.i_TermMarkDateID === Ai_TermMarkDateID
        });
        if(Li_IndexError !== -1) errors.push('');
        return errors;
      },
      getQuarterMarks(Ai_ClassSubjectID) {
        this.a_QuarterMark = [];
        this.a_ClassStudent = this.a_ClassStudent.map(o_Student => ({
          ...o_Student, 
          quarter: null,
          snip: null
        }));
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/class/quarter-mark`, {
          i_ClassSubjectID: Ai_ClassSubjectID,
          i_PlanningPeriodID: this.getPlanningPeriodID
        })
        .then(response => { 
          if (response.status === 401) {
            //this.signOut()
            //this.$smt.logout()
            return
          }
          this.a_QuarterMark = response.map(o_QuarterMark => ({
            ...o_QuarterMark, 
            quarter: o_QuarterMark.b_ExistsMark ? o_QuarterMark.quarter : null,
            snip: o_QuarterMark.b_ExistsSnip ? o_QuarterMark.snip : null
          }));
          this.a_ClassStudent = this.a_ClassStudent.map(o_Student => ({
            ...o_Student, ...this.a_QuarterMark.find(o_QuarterMark => o_QuarterMark.i_AddressRoleID === o_Student.i_AddressRoleID)
          }));
        })
        .catch(error => {
          console.log('Worker error: ', error)
        })
      }
    },
    beforeMount() {
      this.a_Header = [...this.a_ConfigHeader];
      this.getTeacherStudies();
    },
    validations: {
      f_CurrentResult: {
        decimal,
        between: between(0, 6)
      }
    }
  }
</script>